import React, { useState, useEffect } from "react";
import { Pie, Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

import './MessageStats.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const MessageStats2 = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchStats = async () => {
      try {
        console.log("Iniciando requisição para /messages/stats...");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/stats`);
        const { categories, monthlyMessages } = response.data;
  
        console.log("Dados recebidos:", { categories, monthlyMessages });
  
        // Formatar dados mensais para o gráfico
        const formattedMonthlyData = Array(12).fill(0); // Inicializa um array com 12 zeros
  
        monthlyMessages.forEach((m) => {
          const [year, month] = m.date.split("-");
          if (parseInt(year) === new Date().getFullYear()) {
            formattedMonthlyData[parseInt(month) - 1] = m.count; // Ajusta o mês para índice do array
          }
        });
  
        setMonthlyData(formattedMonthlyData);
  
        // Formatar dados de categorias
        const formattedCategoryData = categories.map((c) => ({ label: c._id || "Sem Categoria", value: c.count }));
        setCategoryData(formattedCategoryData);
  
        console.log("Dados formatados para gráficos:", { formattedCategoryData, formattedMonthlyData });
      } catch (error) {
        console.error("Erro ao buscar estatísticas das mensagens:", error);
      }
    };
  
    fetchStats();
  }, []);
 

  // Dados para o gráfico de pizza
  const pieData = {
    labels: categoryData.map((c) => c.label || "Sem Categoria"),
    datasets: [
      {
        data: categoryData.map((c) => c.value),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
      },
    ],
  };

  // Dados para o gráfico de barras
  const barData = {
    labels: [
      `Jan`,
      `Fev`,
      `Mar`,
      `Abr`,
      `Mai`,
      `Jun`,
      `Jul`,
      `Ago`,
      `Set`,
      `Out`,
      `Nov`,
      `Dez`,
    ],
    datasets: [
      {
        label: "Mensagens Recebidas",
        data: monthlyData, // Use o array de 12 posições preenchido corretamente
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        tension: 0.2,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointRadius: 5,
      },
    ],
  };
  

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "left", // Alinha a legenda à esquerda
        labels: {
          boxWidth: 10, // Reduz o tamanho do retângulo da legenda
          padding: 10, // Adiciona espaçamento entre os itens da legenda
          font: {
            size: 12, // Ajusta o tamanho da fonte
          },
        },
      },
      tooltip: {
        enabled: true, // Exibe tooltips ao passar o mouse
      },
    },
  };

  return (
    <div>
      <div className="chart-container">
      <h3 className="chart-title-bar">Mensagens por Mês {currentYear}</h3>
        <div className="content-stats-bar">
          <Bar data={barData} options={barOptions} />
        </div>
      </div>


      {/* <div style={{ maxWidth: "700px", margin: "20px auto" }}>
        <h4>Mensagens por Mês</h4>
        <Bar data={barData} options={barOptions} />
      </div> */}
    </div>
  );
};

export default MessageStats2;