import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './PlanForm.css';

const PlanForm = () => {
  const [plans, setPlans] = useState([]); // Lista de planos
  const [showModal, setShowModal] = useState(false); // Modal para cadastro/edição
  const [editingPlan, setEditingPlan] = useState(null); // Armazena o plano sendo editado
  const [formData, setFormData] = useState({
    category: "",
    description: "",
    price: "",
    notes: "",
    color: "",
  });

  // Carrega os planos ao montar o componente
  useEffect(() => {
    fetchPlans();
  }, []);

  // Buscar todos os planos
  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/plansPhone`);
      setPlans(response.data);
    } catch (error) {
      console.error("Erro ao buscar os planos:", error);
    }
  };

  // Abrir o modal
  const handleOpenModal = (plan = null) => {
    if (plan) {
      // Caso seja edição, preenche os dados do plano
      setEditingPlan(plan);
      setFormData(plan);
    } else {
      // Resetar o formulário para um novo cadastro
      setEditingPlan(null);
      setFormData({
        category: "",
        description: "",
        price: "",
        notes: "",
        color: "",
      });
    }
    setShowModal(true);
  };

  // Fechar o modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Atualizar os dados do formulário
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Submeter o formulário (criar ou editar)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingPlan) {
        // Editar plano
        await axios.put(`${process.env.REACT_APP_API_URL}/plansPhone/${editingPlan._id}`, formData);
        alert("Plano atualizado com sucesso!");
      } else {
        // Criar novo plano
        await axios.post(`${process.env.REACT_APP_API_URL}/plansPhone`, formData);
        alert("Plano cadastrado com sucesso!");
      }
      fetchPlans();
      handleCloseModal();
    } catch (error) {
      console.error("Erro ao salvar o plano:", error);
    }
  };

  // Excluir um plano
  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza que deseja excluir este plano?")) {
      try {
        await axios.delete(`/api/plans/${id}`);
        alert("Plano excluído com sucesso!");
        fetchPlans();
      } catch (error) {
        console.error("Erro ao excluir o plano:", error);
      }
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Novo
    </Tooltip>
  );

  return (
    <Container>
      <div className="table-plan">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Button
            variant="outline-primary"
            className="mb-3"
            size="sm"
            onClick={() => handleOpenModal()}
          >
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
          </Button>
        </OverlayTrigger>
        <h3><i className="fa fa-fw fa-phone-square" />  Planos Telefone Fixo</h3>
      </div>
      <div>
        {/* Tabela de Planos */}
        <div className="tabela-text">
          <Table striped bordered hover >
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Descrição</th>
                <th>Preço</th>
                <th>Observações</th>
                <th>Cor</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {plans.map((plan) => (
                <tr key={plan._id}>
                  <td>{plan.category}</td>
                  <td>{plan.description}</td>
                  <td>{plan.price}</td>
                  <td>{plan.notes}</td>
                  <td>{plan.color}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => handleOpenModal(plan)}
                    >
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(plan._id)}
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Modal de Cadastro/Edição */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingPlan ? "Editar Plano" : "Novo Plano"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Categoria</Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Selecione...</option>
                <option value="Urbano">Urbano</option>
                <option value="Rural">Rural</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Preço</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Observações</Form.Label>
              <Form.Control
                type="text"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cor</Form.Label>
              <Form.Control
                as="select"
                name="color"
                value={formData.color}
                onChange={handleChange}
                required
              >
                <option value="">Selecione...</option>
                <option value="blue">Azul</option>
                <option value="green">Verde</option>
                <option value="yellow">Amarelo</option>
                <option value="red">Vermelho</option>
                <option value="purple">Roxo</option>
                <option value="pink">Rosa</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingPlan ? "Salvar Alterações" : "Cadastrar"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default PlanForm;
