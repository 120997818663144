import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Container, Table } from "react-bootstrap";

import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

const CopywritingRural = () => {
    const [copywritingsRural, setCopywritingsRural] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCopywritingRural, setEditingCopywritingRural] = useState(null);

    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCopywritingsRural();
    }, []);

    const fetchCopywritingsRural = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/copywritingsRural`);
            setCopywritingsRural(response.data);
        } catch (error) {
            console.error("Erro ao buscar os Copywriting:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCopywritingRural(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
    };

    const handleEditCopywritingRural = (copywritingRural) => {
        setIsEditing(true);
        setEditingCopywritingRural(copywritingRural);
        setTitle(copywritingRural.title);
        setSub(copywritingRural.sub);
        setText(copywritingRural.text);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCopywritingRural = async (copywritingRuralId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/copywritings/${copywritingRuralId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCopywritingsRural(); // Atualizar a lista de copywritings

            toast.success('Offer deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o copywriting:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCopywritingRuralId, setDeleteModalCopywritingRuralId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const postData = {
            title,
            sub,
            text
        };

        try {
            if (isEditing && editingCopywritingRural) {
                await axios.put(`${process.env.REACT_APP_API_URL}/copywritingsRural/${editingCopywritingRural._id}`, postData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/copywritingsRural`, postData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchCopywritingsRural();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o postagem:", error);
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );


    return (
        <Container>
            <div className="row">
                <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                    <Button onClick={handleOpenModal} size="sm" variant="outline-primary">
                        <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                    </Button>
                    </OverlayTrigger>
                    <h3><i className="fa fa-fw fa-wifi" />  Plano Internet Rural</h3>
                </div>
                <div className="table-container">
                <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th className="coluna2">Título</th>
                                <th className="coluna3">Subtítulo</th>
                                <th className="coluna4">Texto</th>
                                <th className="coluna5">Ações</th>
                            </tr>
                        </thead>
                        <tbody>

                            {copywritingsRural.map((copywritingRural) => (
                                <tr key={copywritingRural._id}>

                                    <td>{copywritingRural.title}</td>

                                    <td>{copywritingRural.sub}</td>
                                    <td>
                                        <TextRender htmlContent={copywritingRural.text.slice(0, 60) + "..."} />
                                    </td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" size="sm" onClick={() => handleEditCopywritingRural(copywritingRural)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteModalCopywritingRuralId(copywritingRural._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Tem certeza que deseja excluir este copywriting? {deleteModalCopywritingRuralId}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" size="sm" onClick={() => setShowDeleteModal(false)}>
                                                        Cancelar
                                                    </Button>
                                                    <Button variant="danger" size="sm" onClick={() => { handleDeleteCopywritingRural(deleteModalCopywritingRuralId); setShowDeleteModal(false); }}>
                                                        Excluir
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Offer" : "Cadastrar Copywriting"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>

                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </Container>
    );
};

export default CopywritingRural;