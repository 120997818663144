import React from "react";
import TitleAnim from "../../components/TitleAnim";
import 'bootstrap/dist/css/bootstrap.min.css';


import './styles.css'; // Importe o estilo CSS para a animação
import CardPhone from "../../components/HomeComponents/CardPhone";
import CardIpPhone from "../../components/CardIpPhone/CardIpPhone";
import CopywritingPhone from "../../components/CopywritingPhone";
import PlanForm from "../../components/PlanForm/PlanForm";
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";

const Telefone = () => {   

    return (
        <div className="container-fluid table">
           
            <div className="row">                
                <div className="col-sm-12">
                    <br />
                    <CardPhone />
                </div>
            </div>
            <SeparatorBar />
            <div className="row">               
                <div className="col-sm-12">
                    <CopywritingPhone />
                </div>
            </div>
            <SeparatorBar />
            <div className="row">                
                <div className="col-sm-12">
                    <br />
                    <CardIpPhone />
                </div>
            </div>
            <SeparatorBar />
            <div className="row">               
                <div className="col-sm-12">
                    <PlanForm />
                </div>
            </div>
            {/* <div className="row">
                <h3>Carousel/Slider</h3>
                <div className="col-sm-12">
                    <CardPhone />
                </div>
            </div> */}
        </div>
    );
}
export default Telefone;