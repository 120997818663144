import React from "react";
import TitleAnim from "../../components/TitleAnim";
import 'bootstrap/dist/css/bootstrap.min.css';


import './Cidades.css'; // Importe o estilo CSS para a animação
import CidadesComponents from "../../components/CidadesComponents/CidadesComponents";

const Cidades = () => {

    return (
        <div className="container-fluid table">
            
            <div className="row">
                <div className="col-sm-12">
                    <br />
                    <CidadesComponents />
                </div>
            </div>

        </div>
    );
}
export default Cidades;