import React from "react";
import SliderHome from "../../components/HomeComponents/SliderHome";
import PostagemForm from "../../components/PostagemForm/PostagemForm";

import './styles.css';
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";

const Home = () => {
  return(
    <div className="container-fluid table">    
            <div className="container">
                <div className="row">
                    
                    <div className="col-sm-12">
                        <SliderHome />
                       
                    </div>
                </div>
                <SeparatorBar />
                <div className="row">
                    
                    <div className="col-sm-12">
                        <PostagemForm />
                    </div>
                </div>

            </div>
        </div>
  )
}
export default Home;