import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Container, Table } from "react-bootstrap";

// import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import './CardPlans.css';

const CardPlans = () => {
    const [plansInternets, setPlansInternets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingPlanoInternet, setEditingPlanoInternet] = useState(null);
    const [plans, setPlans] = useState("");
    const [description, setDescription] = useState("");
    const [dowload, setDowload] = useState("");
    const [upload, setUpload] = useState("");
    const [price, setPrice] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchPlansInternets();
    }, []);

    const fetchPlansInternets = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/planoInternets`);
            setPlansInternets(response.data);
        } catch (error) {
            console.error("Erro ao buscar os planos:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingPlanoInternet(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPlans("");
        setDescription("");
        setDowload("");
        setUpload("");
        setPrice("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditRural = (planoInternet) => {
        setIsEditing(true);
        setEditingPlanoInternet(planoInternet);
        setPlans(planoInternet.plans);
        setDescription(planoInternet.description);
        setDowload(planoInternet.dowload);
        setUpload(planoInternet.upload);
        setPrice(planoInternet.price);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/planoInternets/${planoInternet.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeletePlanoInternet = async (planoInternetId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_IMG}/planoInternets/${planoInternetId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchPlansInternets(); // Atualizar a lista de planoInternets

            toast.success('PlanoInternet deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o planoInternet:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalPlanoInternetId, setDeleteModalPlanoInternetId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("plans", plans);
        formData.append("description", description);
        formData.append("foto", foto);
        formData.append("dowload", dowload);
        formData.append("upload", upload);
        formData.append("price", price);

        try {
            if (isEditing && editingPlanoInternet) {
                await axios.put(`${process.env.REACT_APP_API_URL}/planoInternets/${editingPlanoInternet._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/planoInternets`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchPlansInternets();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o planoInternet:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const [imagemFundo, setImagemFundo] = useState('');

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <Container>
            <div className="row">
                <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button variant="outline-primary" size="sm" onClick={handleOpenModal}>
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        </Button>
                    </OverlayTrigger>
                    <h3><i class="fa fa-wifi" aria-hidden="true" /> Planos de Internet</h3>
                </div>
                <div className="table-container">
                <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th className="col111">Imagem</th>
                                <th className="col222">Plano de Internet</th>
                                <th className="col333">Descrição</th>
                                <th className="col444">Download</th>
                                <th className="col555">Upload</th>
                                <th className="col666">Preço</th>
                                <th className="acoes">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {plansInternets.map((planoInternet) => (
                                <tr key={planoInternet._id}>
                                    <td>
                                        <div className="planoInternet-img">
                                            <img src={`${process.env.REACT_APP_API_IMG}/assets/planoInternets/${planoInternet.foto}`} alt="PlanoInternet" />
                                        </div>
                                    </td>
                                    <td>{planoInternet.plans}</td>
                                    <td>{planoInternet.description.slice(0, 30) + "..."}</td>
                                    <td>{planoInternet.dowload.slice(0, 15) + "..."}</td>
                                    <td>{planoInternet.upload}</td>
                                    <td>{planoInternet.price}</td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" size="sm" onClick={() => handleEditRural(planoInternet)}>
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteModalPlanoInternetId(planoInternet._id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        Tem certeza que deseja excluir este planoInternet? {deleteModalPlanoInternetId}
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                            Cancelar
                                                        </Button>
                                                        <Button variant="danger" onClick={() => { handleDeletePlanoInternet(deleteModalPlanoInternetId); setShowDeleteModal(false); }}>
                                                            Excluir
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar PlanoInternet" : "Cadastrar PlanoInternet"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Plano de Internet</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={plans} onChange={(e) => setPlans(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Descrição</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Download</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={dowload} onChange={(e) => setDowload(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Upload</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={upload} onChange={(e) => setUpload(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Preço</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} required />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </Container>
    );
};

export default CardPlans;