import React from "react";

import CardSale from "../../components/HomeComponents/CardSale";
import CardOffer from "../../components/HomeComponents/CardOffer";
import SliderHome from "../../components/HomeComponents/SliderHome";
import CardPhone from "../../components/HomeComponents/CardPhone";
import CardRural from "../../components/HomeComponents/CardRural";

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardVideoTv from "../../components/HomeComponents/CardVideoTv";
import Copywriting from "../../components/HomeComponents/CardOffer/Copywriting";
import HeaderImage from "../../components/InternetComponents/HeaderImage";
import CardPlans from "../../components/InternetComponents/CardPlans/CardPlans";
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";


const Internet = () => {

    return (
        <div className="container-fluid table">
            <div className="container">

                <div className="row">
                    <div className="col-sm-12">
                        <br />
                        <HeaderImage />
                        <SeparatorBar />
                    </div>
                   
                    <div className="col-sm-12">
                        <CardPlans />
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Internet;