import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import './MessagesTable.css';

const MessagesTable = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error("Erro ao buscar mensagens:", error);
      toast.error("Erro ao carregar as mensagens.");
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log("Tentando deletar mensagem com ID:", id);
      await axios.delete(`${process.env.REACT_APP_API_URL}/messages/${id}`);
      toast.success("Mensagem excluída com sucesso!");
      fetchMessages(); // Atualiza a tabela
    } catch (error) {
      console.error("Erro ao excluir a mensagem:", error);
      toast.error("Erro ao excluir a mensagem.");
    }
  };

  const handleView = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
  };

  return (
    <div className="container my-4">
      <div className="carousel-h3">
        <h3 className="mb-4">
        <i className="fa fa-fw fa-envelope" />
          {' '}Mensagens do Site
        </h3>
        <div className="mensagens-site">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Assunto</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => (
                <tr key={message._id}>
                  <td>{message.name}</td>
                  <td>{message.email}</td>
                  <td>{message.subject}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      className="mr-sm-2"
                      onClick={() => handleView(message)}
                    >
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </Button>
                    <Button
                      variant="outline-danger"
                      className="mr-sm-2"
                      onClick={() => handleDelete(message._id)}
                    >
                      <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* Modal para Visualizar Mensagem */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes da Mensagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedMessage && (
              <>
                <p>
                  <strong>Nome:</strong> {selectedMessage.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedMessage.email}
                </p>
                <p>
                  <strong>Telefone:</strong> {selectedMessage.tel}
                </p>
                <p>
                  <strong>Assunto:</strong> {selectedMessage.subject}
                </p>
                <p>
                  <strong>Mensagem:</strong> {selectedMessage.message}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MessagesTable;
