import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import SeparatorBar from "../../SeparatorBar/SeparatorBar";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ComboFotoForm = () => {
    const [foto, setFoto] = useState(null); // Foto atual selecionada
    const [showModal, setShowModal] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null); // Pré-visualização durante o upload
    const [fotos, setFotos] = useState([]); // Fotos cadastradas
    const [editFotoId, setEditFotoId] = useState(null); // ID da foto para edição
    const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal de confirmação para deletar
    const [fotoToDelete, setFotoToDelete] = useState(null); // Foto a ser deletada

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG; // Usado para visualizar a imagem cadastrada

    // Fetch de fotos cadastradas
    const fetchFotos = async () => {
        try {
            const response = await axios.get(`${apiUrl}/fotos_combo`);
            setFotos(response.data);
        } catch (error) {
            console.error('Erro ao buscar as fotos:', error);
            toast.error('Erro ao buscar as fotos. Por favor, tente novamente mais tarde.');
        }
    };

    useEffect(() => {
        fetchFotos();
    }, [apiUrl]);

    // Abre o modal para cadastrar/editar foto
    const handleOpenModal = (id = null) => {
        setEditFotoId(id); // Se for edição, armazena o ID da foto
        setShowModal(true);
    };

    // Fecha o modal
    const handleCloseModal = () => {
        setShowModal(false);
        setFoto(null);
        setPreviewUrl(null); // Limpa a pré-visualização ao fechar
    };

    // Manipula a mudança de foto
    const handleFotoChange = (event) => {
        const selectedFile = event.target.files[0];
        setFoto(selectedFile);
        setPreviewUrl(URL.createObjectURL(selectedFile)); // Gera a URL para pré-visualização
    };

    // Submete a foto (cadastrar ou editar)
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!foto) {
            alert('Por favor, selecione uma foto.');
            return;
        }

        const formData = new FormData();
        formData.append("foto", foto);

        try {
            if (editFotoId) {
                // Edita a foto existente
                const response = await axios.put(`${apiUrl}/fotos_combo/${editFotoId}`, formData);
                setFotos(fotos.map(f => f._id === editFotoId ? response.data : f)); // Atualiza a lista de fotos
                toast.success("Foto editada com sucesso!", { position: 'top-right', autoClose: 2000 });
            } else {
                // Cadastra uma nova foto
                await axios.post(`${apiUrl}/fotos_combo`, formData);
                toast.success("Foto cadastrada com sucesso!", { position: 'top-right', autoClose: 2000 });
                fetchFotos(); // Atualiza a lista de fotos chamando o fetch novamente
            }

            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar a foto:", error);
            toast.error("Erro ao cadastrar/editar a foto. Por favor, tente novamente mais tarde.", { position: 'top-right', autoClose: 2000 });
        }
    };

    // Abre o modal de confirmação de exclusão
    const handleDeleteConfirmation = (id) => {
        setFotoToDelete(id); // Define a foto que será excluída
        setShowDeleteModal(true); // Exibe o modal de confirmação
    };

    // Fecha o modal de exclusão
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setFotoToDelete(null);
    };

    // Exclui a foto
    const handleDeleteFoto = async () => {
        try {
            await axios.delete(`${apiUrl}/fotos_combo/${fotoToDelete}`);
            setFotos(fotos.filter(foto => foto._id !== fotoToDelete)); // Remove a foto da lista sem recarregar a página
            toast.success("Foto excluída com sucesso!");
            handleCloseDeleteModal(); // Fecha o modal de exclusão
        } catch (error) {
            console.error("Erro ao excluir a foto:", error);
            toast.error("Erro ao excluir a foto. Por favor, tente novamente mais tarde.");
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Novo
        </Tooltip>
      );

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="title">
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <Button variant="outline-primary" size="sm" onClick={() => handleOpenModal()}>
                                <i className="fa fa-plus-square-o" aria-hidden="true"></i>
                            </Button>
                        </OverlayTrigger>
                        <h3><i className="fa fa-fw fa-clone" />Adicionar foto superior da página Combo Internet + TV</h3>
                    </div>
                    {/* Modal para cadastrar/editar foto */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {editFotoId ? 'Editar Foto' : 'Cadastrar Foto'}
                                <span>Atenção para as dimensões das imagens recomendadas:  (1780x665 pixels)</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Foto</Form.Label>
                                    <Form.Control type="file" accept="image/*" onChange={handleFotoChange} />
                                </Form.Group>

                                {/* Pré-visualização da imagem */}
                                {previewUrl && (
                                    <div className="preview">
                                        <h5>Pré-visualização:</h5>
                                        <img
                                            src={previewUrl}
                                            alt="Pré-visualização"
                                            style={{
                                                width: '100%',
                                                maxHeight: '300px',
                                                objectFit: 'cover',
                                                marginBottom: '15px'
                                            }}
                                        />
                                    </div>
                                )}
                                <br />

                                <Button variant="primary" type="submit">
                                    {editFotoId ? 'Editar' : 'Cadastrar'}
                                </Button>{' '}
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Fechar
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>

            </div>
            <div>

                {/* Exibição das fotos cadastradas */}
                <div className="uploaded-photo-section">
                    <br />
                    <h5>Fotos Cadastradas:</h5>
                    <div className="fotos-grid">
                        {fotos.map((foto) => (
                            <div key={foto._id} className="foto-item">
                                <img
                                    src={`${apiImg}/assets/combos/${foto.foto}`}
                                    alt="Foto cadastrada"
                                    style={{
                                        width: '100%',
                                        maxHeight: '200px',
                                        objectFit: 'cover',
                                        marginBottom: '10px'
                                    }}
                                />
                                <div className="buttons">
                                    <Button variant="warning" onClick={() => handleOpenModal(foto._id)}>
                                        Editar Foto
                                    </Button>{' '}
                                    <Button variant="danger" onClick={() => handleDeleteConfirmation(foto._id)}>
                                        Excluir Foto
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Modal de confirmação para excluir */}
                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmação de Exclusão</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Tem certeza de que deseja excluir esta foto? Esta ação não pode ser desfeita.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDeleteFoto}>
                            Excluir
                        </Button>
                        <Button variant="secondary" onClick={handleCloseDeleteModal}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SeparatorBar />
            </div>

        </>
    );
};

export default ComboFotoForm;
