import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { toast } from "react-toastify";

const FotoForm = ({ postId, onPhotoAdded }) => {
    const [foto, setFoto] = useState(null);
    const [showModal, setShowModal] = useState(false);

    console.log(postId)

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFoto(null);
    };

    const handleFotoChange = (event) => {
        setFoto(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!foto) {
            alert('Por favor, selecione uma foto.');
            return;
        }

        const formData = new FormData();
        formData.append("foto", foto);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/fotos/${postId}`, formData);
            toast.success("Foto cadastrada com sucesso!", {
                position: "top-right",
                autoClose: 2000,
            });
            handleCloseModal();
            onPhotoAdded(); // Chama o callback para atualizar as fotos no PostagemForm
        } catch (error) {
            console.error("Erro ao cadastrar a foto:", error);
            toast.error("Erro ao cadastrar a foto. Por favor, tente novamente mais tarde.", {
                position: "top-right",
                autoClose: 2000,
            });
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Novo
        </Tooltip>
      );

    return (
        <div className="plus">
            <br />
            <h6>Adicionar fotos a postagem</h6>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={handleOpenModal}>
                    <i class="fa fa-image" aria-hidden="true"></i>
                </Button>
            </OverlayTrigger>
            
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Cadastrar Foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Foto</Form.Label>
                            <Form.Control type="file" accept="image/*" onChange={handleFotoChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Cadastrar
                        </Button>{' '}
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Fechar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FotoForm;
