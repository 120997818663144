import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import './Visit.css';

// Registre os elementos necessários
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);


// Registrar os componentes necessários
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Visit2 = () => {
  const [monthlyVisits, setMonthlyVisits] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/visit`)
      .then((response) => {
        const visits = response.data.visits;
        const monthlyData = Array(12).fill(0);

        visits.forEach((visit) => {
          const visitDate = new Date(visit.date);

          // Fuso horário local corrigido
          const adjustedDate = new Date(
            visitDate.getUTCFullYear(),
            visitDate.getUTCMonth(),
            visitDate.getUTCDate(),
            visitDate.getUTCHours(),
            visitDate.getUTCMinutes(),
            visitDate.getUTCSeconds()
          );

          if (adjustedDate.getFullYear() === currentYear) {
            const visitMonth = adjustedDate.getMonth();
            monthlyData[visitMonth] += visit.count;
          }
        });

        setMonthlyVisits(monthlyData);
      })
      .catch((error) =>
        console.error("Erro ao buscar contagem de visitas:", error)
      );
  }, [currentYear]);



  const data = {
    labels: [
      `Jan`,
      `Fev`,
      `Mar`,
      `Abr`,
      `Mai`,
      `Jun`,
      `Jul`,
      `Ago`,
      `Set`,
      `Out`,
      `Nov`,
      `Dez`,
    ],
    datasets: [
      {
        label: `Visitas ao Site (${currentYear})`,
        data: monthlyVisits,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        tension: 0.2,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
        pointRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="visitas">
      <h2>Visitas Mensais - {currentYear}</h2>

      {/* <div style={{ marginBottom: "40px" }}>
        <Bar data={data} options={options} />
      </div> */}

      <div>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default Visit2;
