import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import Copywriting from "./Copywriting";

const CardOffer = () => {
    const [offers, setOffers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingOffer, setEditingOffer] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchOffers();
    }, []);

    const fetchOffers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/offers`);
            setOffers(response.data);
        } catch (error) {
            console.error("Erro ao buscar os offers:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingOffer(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditOffer = (offer) => {
        setIsEditing(true);
        setEditingOffer(offer);
        setTitle(offer.title);
        setSub(offer.sub);
        setText(offer.text);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/offers/${offer.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteOffer = async (offerId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/offers/${offerId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchOffers(); // Atualizar a lista de offers

            toast.success('Offer deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o offer:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalOfferId, setDeleteModalOfferId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("foto", foto);
        formData.append("text", text);

        try {
            if (isEditing && editingOffer) {
                await axios.put(`${process.env.REACT_APP_API_URL}/offers/${editingOffer._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/offers`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchOffers();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o offer:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <div className="container-fluid">
            <div className="row">

                <Card>
                    <Card.Body>

                        <div className="title">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <Button onClick={handleOpenModal} size="sm" variant="outline-primary">
                                    <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                        <div className="table-container">
                            <table className="tabela">
                                <thead>
                                    <tr>
                                        <th className="coluna1">Imagem</th>
                                        <th className="coluna2">Título</th>
                                        <th className="coluna3">Subtítulo</th>
                                        <th className="coluna4">Texto</th>
                                        <th className="coluna5">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {offers.map((offer) => (
                                        <tr key={offer._id}>
                                            <td>
                                                <div className="offer-img">
                                                    <img src={`${process.env.REACT_APP_API_IMG}/assets/offers/${offer.foto}`} alt="Offer" />
                                                </div>
                                            </td>
                                            <td>{offer.title}</td>

                                            <td>{offer.sub}</td>
                                            <td>
                                                <TextRender htmlContent={offer.text} />
                                            </td>
                                            <td>
                                                <div className="botoes">
                                                    <div className="botao">
                                                        <Button variant="outline-success" onClick={() => handleEditOffer(offer)}>
                                                            <i class="fa fa-edit" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                                    <div className="botao">
                                                        <Button
                                                            variant="outline-danger"
                                                            onClick={() => {
                                                                setDeleteModalOfferId(offer._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        >
                                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>

                                                    {/* <Button variant="primary" onClick={() => handleEditOffer(offer)}>Editar</Button>
                                            <Button variant="danger" onClick={() => { setShowDeleteModal(true); setDeleteModalOfferId(offer._id); }}>
                                                Excluir
                                            </Button> */}

                                                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Tem certeza que deseja excluir este offer? {deleteModalOfferId}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                                Cancelar
                                                            </Button>
                                                            <Button variant="danger" onClick={() => { handleDeleteOffer(deleteModalOfferId); setShowDeleteModal(false); }}>
                                                                Excluir
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Offer" : "Cadastrar Offer"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} required />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};

export default CardOffer;