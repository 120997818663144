import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import './CurriculumStats.css';

const CurriculumStatsAreas = () => {
  const [areasStats, setAreasStats] = useState([]); // Estado correto para áreas de interesse

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos/stats/areas`);

        // Atribui os dados diretamente ao estado `areasStats`
        setAreasStats(response.data);
      } catch (error) {
        console.error("Erro ao buscar estatísticas das áreas de interesse:", error);
      }
    };

    fetchStats();
  }, []);

  // Dados para gráfico de pizza (áreas de interesse)
  const areasData = {
    labels: areasStats.map((stat) => stat._id),
    datasets: [
      {
        data: areasStats.map((stat) => stat.count),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "left", // Alinha a legenda à esquerda
        labels: {
          boxWidth: 10, // Reduz o tamanho do retângulo da legenda
          padding: 10, // Adiciona espaçamento entre os itens da legenda
          font: {
            size: 12, // Ajusta o tamanho da fonte
          },
        },
      },
      tooltip: {
        enabled: true, // Exibe tooltips ao passar o mouse
      },
    },
  };

  return (
    <div className="chart-container">
      <h3 className="chart-title">Currículos por Áreas de Interesse</h3>
      <div className="content-stats">
        <Pie data={areasData} options={options} />
      </div>
    </div>
  );
};

export default CurriculumStatsAreas;
