import React from "react";
import TitleAnim from "../../components/TitleAnim";
import 'bootstrap/dist/css/bootstrap.min.css';


import ComboPlanForm from "../../components/ComboForm/ComboPlanForm/ComboPlanForm";
import ComboPlansDisplay from "../../components/ComboForm/ComboPlansDisplay/ComboPlansDisplay";
import ComboCard from "../../components/ComboForm/ComboCard/ComboCard";

import './Combos.css'; // Importe o estilo CSS para a animação
import ComboFotoForm from "../../components/ComboForm/ComboFotoForm/ComboFotoForm";

const Combos = () => {   

    return (
        <div className="container-fluid table">
           
            <div className="row">
                <div className="col-sm-12">
                    <br />
                    <ComboFotoForm />
                    <ComboCard />
                   
                </div>
            </div>           
        </div>
    );
}
export default Combos;