import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import './CurriculumStats.css';

const CurriculumStats = () => {
  const [monthlyStats, setMonthlyStats] = useState(Array(12).fill(0)); // Array de 12 meses, inicializado com zeros
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos/stats/monthly`);
        const stats = response.data;

        const monthlyData = Array(12).fill(0); // Reseta o array de dados mensais

        stats.forEach((stat) => {
          const [year, month] = stat.date.split("-").map(Number);
          if (year === currentYear) {
            monthlyData[month - 1] = stat.count; // Ajusta para o índice do array (0-based)
          }
        });

        setMonthlyStats(monthlyData);
      } catch (error) {
        console.error("Erro ao buscar estatísticas dos currículos:", error);
      }
    };

    fetchStats();
  }, [currentYear]);

  // Dados para gráfico de barras (quantidade mensal)
  const monthlyData = {
    labels: [
      `Jan`,
      `Fev`,
      `Mar`,
      `Abr`,
      `Mai`,
      `Jun`,
      `Jul`,
      `Ago`,
      `Set`,
      `Out`,
      `Nov`,
      `Dez`,
    ],
    datasets: [
      {
        label: `Currículos Recebidos (${currentYear})`,
        data: monthlyStats,
        backgroundColor: "rgba(255, 0, 255, 1)",
        borderColor: "rgba(255, 0, 255, 1)",
        borderWidth: 1,
        fill: false,
        tension: 0.2,
        pointBackgroundColor: "rgba(255, 0, 255, 1)",
        pointRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <div className="chart-container">
        <h3 className="chart-title-bar">Currículos Recebidos por Mês</h3>
        <div className="content-stats-bar">
          <Bar data={monthlyData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default CurriculumStats;
