import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import generateCurriculumPDF from './GenerateCurriculumPDF'; // Importe a função para gerar o PDF

import './CurriculoView.css';

const ListaCurriculos = () => {
  const [curriculos, setCurriculos] = useState([]);

  // Função para buscar todos os currículos
  const fetchCurriculos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/curriculos`);
      setCurriculos(response.data);
    } catch (error) {
      console.error('Erro ao buscar os currículos:', error);
    }
  };

  // Função para gerar o PDF
  const handleGeneratePdf = (curriculo) => {
    generateCurriculumPDF(curriculo._id); // Passa o ID do currículo para a função
  };

  useEffect(() => {
    fetchCurriculos();
  }, []);

  return (
    <div className="container mt-4">
      <div className="carousel-h3">
        <h3><i className="fa fa-th-list" aria-hidden="true"></i> Lista de Currículos</h3>
      </div>
      <div className="list-curriculo">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {curriculos.map((curriculo) => (
              <tr key={curriculo._id}>
                <td>{curriculo.nome}</td>
                <td>{curriculo.email}</td>
                <td>{curriculo.telefone}</td>
                <td className="text-center">
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => handleGeneratePdf(curriculo)}
                  >
                    <i className="fa fa-eye" aria-hidden="true"></i> Gerar PDF
                  </Button>
                  {' '}
                  {/* Adicione outros botões se necessário */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListaCurriculos;
