import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import axios from "axios";

import './ChatbotStats.css';

const ChatbotStats = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/chatbot/stats`);
        const { categoryStats, monthlyStats } = response.data;

        setCategoryData(categoryStats.map((c) => ({ label: c._id || "Sem Assunto", value: c.count })));
        setMonthlyData(monthlyStats);
      } catch (error) {
        console.error("Erro ao buscar estatísticas do chatbot:", error);
      }
    };

    fetchStats();
  }, []);

  // Gráfico de Pizza - Categorias
  const pieData = {
    labels: categoryData.map((c) => c.label),
    datasets: [
      {
        data: categoryData.map((c) => c.value),
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800"],
      },
    ],
  };

  // Gráfico de Barras - Mensal
  const barData = {
    labels: monthlyData.map((m) => m.date),
    datasets: [
      {
        label: "Interações por Mês",
        data: monthlyData.map((m) => m.count),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "left", // Alinha a legenda à esquerda
        labels: {
          boxWidth: 10, // Reduz o tamanho do retângulo da legenda
          padding: 10, // Adiciona espaçamento entre os itens da legenda
          font: {
            size: 12, // Ajusta o tamanho da fonte
          },
        },
      },
      tooltip: {
        enabled: true, // Exibe tooltips ao passar o mouse
      },
    },
  };

  return (
    <div>
      <div className="chart-container">
      <h3 className="chart-title">Chatbot por Categoria</h3>
        <div className="content-stats">
          <Pie data={pieData} options={options} />
        </div>
      </div>

      {/* <div style={{ maxWidth: "700px", margin: "20px auto" }}>
        <h4>Interações por Mês</h4>
        <Bar data={barData} options={barOptions} />
      </div> */}
    </div>
  );
};

export default ChatbotStats;
