import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Container, Table } from "react-bootstrap";

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const SliderHome = () => {
    const [carousels, setCarousels] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingCarousel, setEditingCarousel] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCarousels();
    }, []);

    const fetchCarousels = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/carousels`);
            setCarousels(response.data);
        } catch (error) {
            console.error("Erro ao buscar os carousels:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingCarousel(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditCarousel = (carousel) => {
        setIsEditing(true);
        setEditingCarousel(carousel);
        setTitle(carousel.title);
        setSub(carousel.sub);
        setText(carousel.text);
        setPreviewImage(`${process.env.REACT_APP_API_URL}/assets/carousels/${carousel.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteCarousel = async (carouselId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/carousels/${carouselId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchCarousels(); // Atualizar a lista de carrosséis

            toast.success('Carousel deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o carousel:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalCarouselId, setDeleteModalCarouselId] = useState(null);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("foto", foto);
        formData.append("text", text);

        try {
            if (isEditing && editingCarousel) {
                await axios.put(`${process.env.REACT_APP_API_URL}/carousels/${editingCarousel._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/carousels`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchCarousels();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o carousel:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Novo
        </Tooltip>
    );

    return (
        <Container>
        <div className="row">
            <div className="title">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button variant="outline-primary" size="sm" onClick={handleOpenModal}>
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                        </Button>
                    </OverlayTrigger>
                        <h3><i class="fa fa-sliders" aria-hidden="true" /> Carousel/Slider</h3>
                </div>
                <div className="table-container">
                <Table striped bordered hover >
                        <thead>
                            <tr >
                                <th className="imagem-th">Vídeo</th>
                                <th>Título</th>
                                <th>Subtítulo</th>
                                <th>Texto</th>
                                <th className="acoes">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {carousels.map((carousel) => (
                                <tr key={carousel._id} >
                                    <td>
                                        <div className="offer-img">
                                            <img src={`${process.env.REACT_APP_API_IMG}/assets/carousels/${carousel.foto}`} alt="Carousel" />
                                        </div>
                                    </td>
                                    <td>{carousel.title}</td>
                                    <td>{carousel.sub}</td>
                                    <td><TextRender htmlContent={carousel.text.slice(0, 100) + "..."} />
                                    </td>
                                    <td>
                                        <div className="botoes">
                                            <div className="botao">
                                                <Button variant="outline-success" size="sm" onClick={() => handleEditCarousel(carousel)} >
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <div className="botao">
                                                <Button variant="outline-danger" size="sm" onClick={() => { setShowDeleteModal(true); setDeleteModalCarouselId(carousel._id); }}>
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </Button>
                                            </div>
                                            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    Tem certeza que deseja excluir este carousel?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                        Cancelar
                                                    </Button>
                                                    <Button variant="danger" onClick={() => { handleDeleteCarousel(deleteModalCarouselId); setShowDeleteModal(false); }}>
                                                        Excluir
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isEditing ? "Editar Carousel" : "Cadastrar Carousel"}
                            <span>Atenção para as dimensões recomendadas para a imagem:  (1780x665 pixels)</span>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} required />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </Container >
    );
};

export default SliderHome;