import React from "react";
import Visit from "./Visit/Visit";
import Visit2 from "./Visit/Visit2";
import MessageStats from "./MessageStats/MessageStats";
import ChatbotStats from "./ChatbotStats/ChatbotStats";

import './Dashboard.css';
import CurriculumStats from "./CurriculumStats/CurriculumStats";
import MessageStats2 from "./MessageStats/MessageStats2";
import CurriculumStatsAreas from "./CurriculumStats/CurriculumStatsAreas";

const Dashboard = () => {
  return (
    <div className="container-fluid dashboard-container">
      <div className="row g-3 mt-1">
        <div className="col-sm-12 col-md-4">
          <Visit />
        </div>
        <div className="col-sm-12 col-md-4">
          <MessageStats2 />
        </div>
        <div className="col-sm-12 col-md-4">
          <CurriculumStats />
        </div>
      </div>

      <div className="row g-3 mt-1">
        <div className="col-sm-12 col-md-6 col-lg-3">
          <ChatbotStats />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <MessageStats />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <CurriculumStatsAreas />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <ChatbotStats />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
