import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardCategory from '../../components/CardCategory';
import CardProduct from '../../components/CardProduct';

import './Products.css';
import SeparatorBar from '../../components/SeparatorBar/SeparatorBar';

const Products = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  // Função para buscar categorias
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Erro ao buscar as categorias:", error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">          
          <CardCategory categories={categories} setCategories={setCategories} />
          <br /><br />
          <SeparatorBar />
          <CardProduct categories={categories} />
        </div>
      </div>

    </>
  )
}
export default Products;