import React from "react";
import FadeInCard from "../../components/FadeInCard";
import './styles.css';
import CardRural from "../../components/HomeComponents/CardRural";
import CopywritingRural from "../../components/RuralComponents/Copywriting";
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";

const RuralInternet = () => {
    return (
        <div className="container table">
            <div className="row">                
                <div className="col-sm-12">
                    <br />
                    <CardRural />
                    <br />
                </div>
                <SeparatorBar />               
                <div className="col-sm-12">
                    <br />
                    <CopywritingRural />
                </div>
            </div>
        </div>
    )
}
export default RuralInternet;